var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.upsellGroup
    ? _c(
        "div",
        [
          _c("context-title", {
            attrs: {
              title: _vm.title,
              passedActions: _vm.primaryTitleBarActions,
            },
          }),
          _c("LineItem", { staticClass: "row no-gutters" }, [
            _c(
              "div",
              { staticClass: "col-12" },
              [
                _c("FormInput", {
                  staticClass: "large block-xs--xs",
                  attrs: {
                    label: "Group Name",
                    spellcheck: "true",
                    required: "true",
                    labelSize: "partner-form",
                  },
                  model: {
                    value: _vm.groupName,
                    callback: function ($$v) {
                      _vm.groupName = $$v
                    },
                    expression: "groupName",
                  },
                }),
              ],
              1
            ),
          ]),
          _c("LineItem", { staticClass: "row no-gutters" }, [
            _c("div", { staticClass: "col-12" }, [
              _c("div", { staticClass: "label block-xs--xs" }, [
                _vm._v("Items:"),
              ]),
            ]),
          ]),
          _c(
            "v-container",
            { staticClass: "fill-height pa-0", attrs: { fluid: "" } },
            [
              _c(
                "v-row",
                [
                  _c("EditCard", {
                    attrs: { group: _vm.upsellGroup },
                    on: { onChange: _vm.handleChange },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm.navigationConfirmationModal
            ? _c(
                "EditingModal",
                { attrs: { size: "sm" } },
                [
                  _c(
                    "AlertDialog",
                    {
                      attrs: {
                        title: "Unsaved Changes",
                        onExit: () => (_vm.navigationConfirmationModal = false),
                        actions: _vm.navigationConfirmationActions,
                      },
                    },
                    [
                      _vm._v(
                        "You have unsaved changes, would you like to save these changes?"
                      ),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }