<template>
  <div v-if="upsellGroup">
    <context-title :title="title" :passedActions="primaryTitleBarActions">
    </context-title>
    <LineItem class="row no-gutters">
      <div class="col-12">
        <FormInput
          v-model="groupName"
          class="large block-xs--xs"
          label="Group Name"
          spellcheck="true"
          required="true"
          labelSize="partner-form"
        />
      </div>
    </LineItem>
    <LineItem class="row no-gutters">
      <div class="col-12">
        <div class="label block-xs--xs">Items:</div>
      </div>
    </LineItem>
    <v-container class="fill-height pa-0" fluid>
      <v-row  >
        <EditCard :group="upsellGroup" @onChange="handleChange"/>
      </v-row>
    </v-container>
    <EditingModal v-if="navigationConfirmationModal" size="sm">
      <AlertDialog
        title="Unsaved Changes"
        :onExit="() => navigationConfirmationModal = false"
        :actions="navigationConfirmationActions"
      >You have unsaved changes, would you like to save these changes?</AlertDialog>
    </EditingModal>
  </div>
</template>

<script>
import ContextTitle from "components/context_title.vue";
import EditCard from "components/upsell/upsell_group_edit_card.vue";
import ActionButton from "components/action_button";
import TextButton from "components/text_button.vue";
import EditingModal from "components/editing_modal.vue";
import AlertDialog from "components/alert_dialog.vue";
import Toggle from "components/cleverly/Toggle.vue";
import FormInput from "components/cleverly/FormInput.vue";
import LineItem from "components/line_item.vue";

import { mapActions, mapGetters } from "vuex";

import { UpsellDataHelper } from "mixins/upsell.js";
import clone from "clone";

export default {
    name: "UpsellGroupEdit",
    data() {
        return {
            dirtyUpsellGroup: undefined,
            pendingRoute: undefined,
            navigationConfirmationModal: false,
            navigationConfirmationActions: [
                {
                    type: "remove-red",
                    display: "No",
                    run: this.confirmNavigation
                },
                {
                    type: "proceed-green",
                    display: "Yes",
                    run: () => this.save().then(() => this.confirmNavigation)
                }
            ]
        };
    },
    components: {
        ContextTitle,
        EditCard,
        ActionButton,
        TextButton,
        AlertDialog,
        EditingModal,
        Toggle,
        FormInput,
        LineItem
    },
    mixins: [UpsellDataHelper],
    computed: {
        primaryTitleBarActions() {
            const previous = {
                type: "cancel",
                display: "Cancel",
                run: () => {
                    this.leave();
                }
            };
            if (!this.dirty) return [previous];

            const saveClose = {
                type: "proceed-green",
                display: "Save",
                run: () => {
                    this.save().then(() => this.leave());
                }
            };

            return [previous, saveClose];
        },
        upsellGroup() {
            return {
                GroupID: 0,
                items: [],
                GroupName: "",
                Enabled: true,
            }
        },
        dirty() {
            return this.dirtyUpsellGroup != undefined;
        },
        title() {
            if (this.groupName == "") return 'Add Group'
            return `Add Group - ${this.groupName}`
        },
        groupName: {
            get() {
                if (this.dirtyUpsellGroup != undefined)
                    return this.dirtyUpsellGroup.GroupName;
                return this.upsellGroup.GroupName;
            },
            set(newValue) {
                if (this.dirtyUpsellGroup == undefined) {
                    this.dirtyUpsellGroup = clone(this.upsellGroup);
                }
                this.dirtyUpsellGroup.GroupName = newValue;
            }
        },
        groupEnabled: {
            get() {
                if (this.dirtyUpsellGroup != undefined)
                    return this.dirtyUpsellGroup.Enabled;
                return this.upsellGroup.Enabled;
            },
            set(newValue) {
                if (this.dirtyUpsellGroup == undefined) {
                    this.dirtyUpsellGroup = clone(this.upsellGroup);
                }
                this.dirtyUpsellGroup.Enabled = newValue;
            }
        }
    },
    methods: {

        leave() {
            this.guardRouteAway({
                name: "upsell-group"
            });
        },
        guardRouteAway(route) {
            if (this.dirtyUpsellGroup === undefined) {
                this.$router.push(route);
                return;
            }

            this.pendingRoute = route;
            this.navigationConfirmationModal = true;
        },
        confirmNavigation() {
            this.navigationConfirmationModal = false;
            this.$router.push(this.pendingRoute);
            this.pendingRoute = undefined;
        },
        save() {
            if (!this.validateForm()) return Promise.reject()
            let waypointID = this.$route.params.store;
            return this.createUpsellGroup({
                waypointID,
                upsellGroup: this.dirtyUpsellGroup
            }).then(() => {
                this.dirtyUpsellGroup = undefined;
                return Promise.resolve(true);
            });
        },
        validateForm() {
            if (this.dirtyUpsellGroup === undefined) return false
            if (this.dirtyUpsellGroup.GroupName == "") {
                this.toast("Group name is required.", 'error')
                return false
            } else if (this.dirtyUpsellGroup.items.length == 0) {
                this.toast("At least 1 upsell item is required.", 'error')
                return false
            } 
            return true
        },
        handleChange(items) {
            if (this.dirtyUpsellGroup === undefined) {
                this.dirtyUpsellGroup = clone(this.upsellGroup)
            }
            this.dirtyUpsellGroup = {
                ...this.dirtyUpsellGroup,
                items: items
            };
        },
        ...mapActions("UpsellStore", ["createUpsellGroup"])
    },
};
</script>

<style lang="scss" scoped>
@import "~scss/variables";
@import "~scss/mixins";

input {
  @include below(xs) {
    width: 100%;
  }
}

.form-input {
  input {
    @include above(md) {
      width: 331px;
    }
  }
}

.block-label > label {
  @include roboto-bold;
  display: block;
  font-size: fz(sm);
  color: $grey_mid_1;
  margin: spacing(xs) 0px;
  width: auto;
}

.line-item {
  padding: 0px 30px;
  margin-right: 0;
}

.label-offset {
  margin-left: 155px;
  &.line-item {
    margin-right: -30px;
    &:before {
      display: none;
    }
  }
}
</style>

